import React, { useState } from 'react';
import { Modal } from '../../components';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { post } from '../../util/api';
import css from './SectionQA.module.css';

function SectionQA(props) {
  const { listing, author, onManageDisableScrolling, currentUser } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [question, setQuestion] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [success, setSuccess] = useState(false);
  const currentQuestions = listing?.attributes?.publicData?.questions ?? [];
  const hasQuestions = currentQuestions && currentQuestions.length > 0;
  const hasAnsweredQuestions =
    hasQuestions && currentQuestions.find(q => q.answer && !q.answer?.isPrivate);
  const titleLabel = 'Q&A';

  const handleSubmitQuestion = () => {
    if (question) {
      const newQuestionsArray = [
        ...currentQuestions,
        {
          question,
          listingId: listing.id.uuid,
          listingName: listing.attributes.title,
          customerName: currentUser.attributes.profile.displayName,
          customerId: currentUser.id.uuid,
          isPrivate,
        },
      ];

      const body = {
        updateData: {
          id: listing.id.uuid,
          publicData: {
            questions: newQuestionsArray,
          },
        },
      };

      return post('/api/update-listing', body)
        .then(resp => {
          setSuccess(true);
        })
        .catch(e => console.log(e));
    }
  };

  return (
    <div className={css.sectionWrapper}>
      <div className={css.title}>
        <span className={css.titleLabel}>{titleLabel}</span>
        {currentUser && (
          <div className={css.questionButton} onClick={() => setModalOpen(true)}>
            Ask a question
          </div>
        )}
      </div>

      <div className={css.questionsWrapper}>
        {hasAnsweredQuestions ? (
          <div className={css.questionsWrapper}>
            {currentQuestions
              .filter(q => !q.isPrivate)
              .map(q => {
                return (
                  <div className={css.question}>
                    <div className={css.questionLeft}>
                      <strong>Q:</strong> <span>{q.question}</span>
                    </div>
                    <div className={css.questionRight}>
                      <strong>A:</strong> <span>{q.answer}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <p>There are no questions on this listing yet.</p>
        )}
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setQuestion(null);
          setIsPrivate(false);
          setSuccess(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {success ? (
          <div>
            <center>
              <h2>Your question was sent</h2>
            </center>
          </div>
        ) : (
          <div>
            <center>
              <h2>Ask a question</h2>
            </center>
            <input
              type="text"
              value={question}
              onChange={e => setQuestion(e.target.value)}
              placeholder="Ask a question..."
            />

            <div className={css.sectionBottom}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPrivate}
                      onChange={event => setIsPrivate(event.target.checked)}
                    />
                  }
                  label={isPrivate ? 'My question is private' : 'My question is public'}
                />
              </FormGroup>

              <div
                onClick={handleSubmitQuestion}
                className={question ? css.submitButton : css.submitButtonDisabled}
              >
                Submit
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default SectionQA;
