import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { pushToPath } from '../../util/urlHelpers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  Modal,
  Button,
  PrimaryButton,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import SectionGallery from './SectionGallery';
import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  sendOffer,
  sendSwapRequest,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './ListingPage.module.css';
import SectionQA from './SectionQA';
import { ToastContainer, toast } from 'react-toastify';
import { util as sdkUtil } from '../../util/sdkLoader';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      referralActivated: false,
      sameVendorWarningModalOpen: false,
      backupDressModal: false,
      offerModalOpen: false,
      offerValue: null,
      swapRequestModalOpen: false,
      ownListings: [],
      selectedSwapListing: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handleReferralClick = this.handleReferralClick.bind(this);
    this.setSameVendorWarningModalOpen = this.setSameVendorWarningModalOpen.bind(this);
    this.setBackupDressModal = this.setBackupDressModal.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('react-toastify/dist/ReactToastify.css');
    }

    const customImagesVariants = {
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 400,
        h: 600,
        fit: 'scale',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 800,
        h: 1200,
        fit: 'scale',
      }),
    };

    sdk.ownListings
      .query({
        include: ['images'],
        'fields.image': ['variants.portrait-crop', 'variants.portrait-crop2x'],
        ...customImagesVariants,
      })
      .then(res => {
        const userListings = res.data.data;
        const images = res.data?.included;

        const listingsWithImages =
          userListings &&
          userListings
            .filter(l => {
              return l.attributes.state === 'published';
            })
            .map(l => {
              let finalListing = { ...l };
              const foundImagesArray = [];
              const listingImagesIds = l.relationships.images.data;

              images.forEach(i => {
                const foundId = listingImagesIds.find(x => {
                  return x.id.uuid === i.id.uuid;
                });
                const isAlreadyPushed = foundImagesArray.find(y => {
                  return y.id.uuid === i.id.uuid;
                });

                if (foundId && !isAlreadyPushed) {
                  foundImagesArray.push(i);
                }
              });

              finalListing.images = foundImagesArray;

              return finalListing;
            });

        this.setState({
          ownListings: listingsWithImages,
        });
      })
      .catch(e => console.log(e));
  }

  setSameVendorWarningModalOpen(value) {
    this.setState({
      sameVendorWarningModalOpen: value,
    });
  }

  setBackupDressModal(value) {
    this.setState({
      backupDressModal: value,
    });
  }

  handleReferralClick() {
    this.setState({ referralActivated: true });
  }

  handleSubmit(values) {
    const referral = this.state.referralActivated;

    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, quantity, donation, ...bookingData } = values;
    bookingData.referral = referral;
    bookingData.quantity = quantity;
    bookingData.donationAmount = donation?.amount;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: bookingDates
        ? {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          }
        : {},
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onSendSwapRequest,
    } = this.props;

    const handleSwapRequest = () => {
      const { history, params, onSendEnquiry } = this.props;
      const routes = routeConfiguration();
      const listingId = new UUID(params.id);
      const selectedSwapListingObj = {
        title: this.state.selectedSwapListing.attributes.title,
        id: this.state.selectedSwapListing.id.uuid,
        imageUrl: this.state.selectedSwapListing?.images[0]?.attributes?.variants['portrait-crop']
          ?.url,
      };
      const data = {
        protectedData: {
          swapListing: selectedSwapListingObj,
        },
      };
      onSendSwapRequest(listingId, data)
        .then(txId => {
          this.setState({ swapRequestModalOpen: false });

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
          );
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    };

    if (typeof window === 'undefined') {
      return null;
    }
    const isAll20 = false; //location?.state?.isAll20;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const isProductForSale =
      Array.isArray(currentListing?.attributes.publicData?.isProductForSale) &&
      currentListing?.attributes.publicData?.isProductForSale[0] === 'true';

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage
        id={isProductForSale ? 'ListingPage.buyingTitle' : 'ListingPage.bookingTitle'}
        values={{ title: richTitle }}
      />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { priceTitle } = priceData(price, intl);

    const price20 = {
      ...price,
      amount: 2000,
    };
    const formattedPrice = isAll20
      ? priceData(price20, intl).formattedPrice
      : priceData(price, intl).formattedPrice;
    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else if (isProductForSale) {
        return sdk.currentUser
          .show()
          .then(res => {
            const currentShoppingCart = res.data.data.attributes.profile.publicData.shoppingCart
              ? res.data.data.attributes.profile.publicData.shoppingCart
              : [];
            const currentShoppingCartUnwrapped = currentShoppingCart.map(item => {
              return {
                listing: JSON.parse(item.listing),
                checkoutValues: JSON.parse(item.checkoutValues),
              };
            });

            const isFromSameVendor =
              currentShoppingCartUnwrapped.length === 0 ||
              currentShoppingCartUnwrapped.find(item => {
                return item.listing.author.id.uuid === currentListing.author.id.uuid;
              });

            if (isFromSameVendor) {
              let currentListingCopy = { ...currentListing };

              if (isAll20) {
                currentListingCopy.attributes.price.amount = 2000;
              }
              //ADD TO CART

              const shoppingCartItem = {
                listing: JSON.stringify({ ...currentListingCopy }),
                checkoutValues: JSON.stringify({ ...values }),
              };

              return sdk.currentUser
                .updateProfile({
                  publicData: {
                    shoppingCart: [...currentShoppingCart, shoppingCartItem],
                  },
                })
                .then(res => {
                  // this.setBackupDressModal(true);
                  toast('Added to cart');
                })
                .catch(e => console.log(e));
            } else {
              this.setSameVendorWarningModalOpen(true);
            }
          })
          .catch(e => console.log(e));
      } else {
        this.handleSubmit(values); //send to checkout function
      }
    };

    const handleDonationSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values); //send to checkout function
      }
    };

    const clearBasket = () => {
      return sdk.currentUser
        .updateProfile({
          publicData: {
            shoppingCart: [],
          },
        })
        .then(res => {
          return window.location.reload();
        })
        .catch(e => console.log(e));
    };
    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const seeMoreLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ProfilePage"
        params={{ id: ensuredAuthor?.id?.uuid }}
        to={{ hash: '#host' }}
      >
        <FormattedMessage id="ListingPage.seeMore" />
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    const referralNewUser = currentUser
      ? currentUser.attributes.profile.privateData.referral
      : false;
    const referralSenderUser = currentUser
      ? currentUser.attributes.profile.privateData.referralsArray
      : false;
    const referralLeftForSenderUser = referralSenderUser
      ? referralSenderUser.filter(item => {
          return item.used;
        }).length
      : false;
    const referral = referralNewUser || referralLeftForSenderUser;
    const stock = currentListing.attributes.publicData?.stock;

    const currentShopCart = currentUser?.attributes.profile.publicData.shoppingCart
      ? currentUser.attributes.profile.publicData.shoppingCart
      : [];

    const currentShopCartUnwrapped = currentShopCart.map(item => {
      return {
        listing: JSON.parse(item.listing),
        checkoutValues: JSON.parse(item.checkoutValues),
      };
    });

    const hostIdOfFirstItem =
      currentShopCartUnwrapped.length > 0
        ? currentShopCartUnwrapped[0].listing.author.id.uuid
        : currentListing.author.id.uuid;

    const openOfferModal = () => {
      this.setState({
        offerModalOpen: true,
      });
    };

    const currentListingType = currentListing?.attributes?.publicData?.listingType;
    const isDonationListing =
      currentListingType === 'donation' || currentListingType === 'carbonFootprint';
    const isCarbonFootprint = currentListingType === 'carbonFootprint';

    if (isDonationListing) {
      return (
        <Page
          title={schemaTitle}
          scrollingDisabled={scrollingDisabled}
          author={authorDisplayName}
          contentType="website"
          description={description}
          facebookImages={facebookImages}
          twitterImages={twitterImages}
          schema={{
            '@context': 'http://schema.org',
            '@type': 'ItemPage',
            description: description,
            name: schemaTitle,
            image: schemaImages,
          }}
        >
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div>
                <div className={css.contentContainer}>
                  <div className={css.mainContent}>
                    <div className={css.galleryWrapper}>
                      <SectionGallery listing={currentListing} />
                    </div>
                  </div>
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleDonationSubmit}
                    title={isDonationListing ? richTitle : bookingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    referral={referral}
                    handleReferralClick={this.handleReferralClick}
                    referralLeftForSenderUser={referralLeftForSenderUser}
                    isProductForSale={isProductForSale}
                    isAll20={isAll20}
                    openOfferModal={openOfferModal}
                    onContactUser={this.onContactUser}
                    openSwapModal={() =>
                      this.setState({
                        swapRequestModalOpen: true,
                      })
                    }
                    isDonationListing={isDonationListing}
                    isCarbonFootprint={isCarbonFootprint}
                  />
                </div>
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              {/* <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              /> */}

              <div className={css.contentContainer}>
                {/* <SectionAvatar user={currentAuthor} params={params} /> */}
                <div className={css.mainContent}>
                  <div className={css.galleryWrapper}>
                    <div
                      className={css.chatButton}
                      onClick={() => this.onContactUser(currentAuthor)}
                    >
                      Chat
                    </div>
                    <SectionGallery listing={currentListing} />
                    <div className={css.showClosetButton}>
                      <NamedLink
                        className={css.closetLink}
                        name="ProfilePage"
                        params={{ id: ensuredAuthor?.id?.uuid }}
                        // to={{ hash: '#host' }}
                      >
                        {' '}
                        Show closet
                      </NamedLink>
                    </div>
                  </div>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    listingIdObj={currentListing.id}
                    isProductForSale={isProductForSale}
                    history={this.props.history}
                    seeMoreLink={seeMoreLink}
                  />
                  <SectionDescriptionMaybe description={description} />
                  {/* <SectionQA
                    listing={currentListing}
                    author={ensuredAuthor}
                    onManageDisableScrolling={onManageDisableScrolling}
                    currentUser={currentUser}
                  /> */}
                  {/* <SectionFeaturesMaybe options={amenityOptions} publicData={publicData} /> */}
                  {/* <SectionRulesMaybe publicData={publicData} /> */}
                  {/* <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  /> */}
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />

                  {/* <SectionFilteredSearches
                    currentListingId={currentListing?.id?.uuid}
                    publicData={publicData}
                  /> */}
                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
                {stock && stock === '0' ? (
                  <div className={css.outOfStockWrapper}>
                    <p>Out of stock</p>
                  </div>
                ) : (
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    referral={referral}
                    handleReferralClick={this.handleReferralClick}
                    referralLeftForSenderUser={referralLeftForSenderUser}
                    isProductForSale={isProductForSale}
                    isAll20={isAll20}
                    openOfferModal={openOfferModal}
                    onContactUser={this.onContactUser}
                    openSwapModal={() =>
                      this.setState({
                        swapRequestModalOpen: true,
                      })
                    }
                  />
                )}
              </div>

              {/* swap request modal */}

              <Modal
                isOpen={this.state.swapRequestModalOpen}
                onClose={() => {
                  this.setState({
                    swapRequestModalOpen: false,
                  });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.swapModalWrapper}>
                  <center>
                    <h2>Pick your swap item</h2>

                    <div className={css.swapItemsWrapper}>
                      {this.state.ownListings.map(l => {
                        const listingTitle = l?.attributes?.title;
                        const listingImage =
                          l?.images[0]?.attributes?.variants['portrait-crop']?.url;
                        const isSelected = this.state.selectedSwapListing?.id?.uuid === l.id.uuid;
                        return (
                          <div
                            className={
                              isSelected ? css.selectedSwapListingWrapper : css.swapListingWrapper
                            }
                            onClick={() => this.setState({ selectedSwapListing: l })}
                          >
                            <img src={listingImage} className={css.swapListingImage} />
                            <p className={css.swapListingTitle}>{listingTitle}</p>
                          </div>
                        );
                      })}
                    </div>

                    <Button
                      disabled={!this.state.selectedSwapListing}
                      onClick={this.state.selectedSwapListing ? handleSwapRequest : () => {}}
                    >
                      Send swap request
                    </Button>
                  </center>
                </div>
              </Modal>

              {/* not same vendor warning */}

              <Modal
                isOpen={this.state.sameVendorWarningModalOpen}
                onClose={() => {
                  this.setSameVendorWarningModalOpen(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <center>
                  <h2>
                    <FormattedMessage id="ListingPage.sameVendorModalTitle" />
                  </h2>
                </center>

                <div className={css.modalButtonsWrapper}>
                  <Button type="button" className={css.modalButton} onClick={clearBasket}>
                    <FormattedMessage id="ListingPage.clearBasket" />
                  </Button>

                  <Button
                    type="button"
                    className={css.modalButton}
                    onClick={() =>
                      pushToPath(`/s?pub_hostId=${hostIdOfFirstItem}&pub_isProductForSale=true`)
                    }
                  >
                    <FormattedMessage id="ListingPage.seeSameVendorListings" />
                  </Button>
                </div>
              </Modal>

              {/* backup dress modal */}
              <Modal
                isOpen={this.state.backupDressModal}
                onClose={() => {
                  this.setBackupDressModal(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <center>
                  <h2>
                    <FormattedMessage id="ListingPage.backupDressTitle" />
                  </h2>
                </center>

                <div className={css.modalButtonsWrapper}>
                  <Button
                    type="button"
                    className={css.modalButton2}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        return window.location.reload();
                      }
                    }}
                  >
                    <FormattedMessage id="ListingPage.noThanks" />
                  </Button>

                  <Button
                    type="button"
                    className={css.modalButton2}
                    onClick={() =>
                      pushToPath(
                        `/s?pub_hostId=${hostIdOfFirstItem}&pub_isProductForSale=true&pub_only20Dollar=true`
                      )
                    }
                  >
                    <FormattedMessage id="ListingPage.seeSameVendorListingsOnly20" />
                  </Button>
                </div>
              </Modal>

              {/* Make an offer modal */}
              <Modal
                isOpen={this.state.offerModalOpen}
                onClose={() => {
                  this.setState({
                    offerModalOpen: false,
                  });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.offerModalWrapper}>
                  <center>
                    <h2>Make an offer</h2>
                  </center>
                  <div className={css.offerModalFieldWrapper}>
                    <p>Your offer in AUD</p>
                    <input
                      type="number"
                      placeholder="50"
                      className={css.offerModalField}
                      min={1}
                      value={this.state.offerValue}
                      onChange={e => {
                        return this.setState({
                          offerValue: e.target.value,
                        });
                      }}
                    />

                    <PrimaryButton
                      type="button"
                      className={css.submutOfferButton}
                      disabled={!this.state.offerValue && this.state.offerValue < 1}
                      onClick={() => {
                        return this.props
                          .onSendOffer(listingId, null, this.state.offerValue)
                          .then(txId => {
                            this.setState({ offerModalOpen: false });
                            const routes = routeConfiguration();
                            // Redirect to OrderDetailsPage
                            this.props.history.push(
                              createResourceLocatorString(
                                'OrderDetailsPage',
                                routes,
                                { id: txId.uuid },
                                {}
                              )
                            );
                          })
                          .catch(() => {
                            // Ignore, error handling in duck file
                          });
                      }}
                    >
                      Submit offer
                    </PrimaryButton>
                  </div>
                </div>
              </Modal>

              <ToastContainer />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onSendOffer: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSendSwapRequest: (listingId, data) => dispatch(sendSwapRequest(listingId, data)),
  onSendOffer: (listingId, message, proposedPrice) =>
    dispatch(sendOffer(listingId, message, proposedPrice)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
