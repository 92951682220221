import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { Interweave } from 'interweave';
import { Markup } from 'interweave';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description } = props;

  const isNewTypeDescription = description
    ? description.includes('<p>') || description.includes('＜p＞')
    : true;

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      {isNewTypeDescription ? (
        <Interweave content={description.replaceAll('＜', '<').replaceAll('＞', '>')} />
      ) : (
        <p className={css.description}>
          {richText(description, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
            longWordClass: css.longWord,
          })}
        </p>
      )}
      <p className={css.description}>
        {/* {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })} */}
        <Interweave
          content={description
            .replaceAll('＜', '<')
            .replaceAll('＞', '>')
            .replaceAll('<div>', '')
            .replaceAll('</div>', '')}
        />
      </p>{' '}
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
