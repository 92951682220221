import React, { Component, useState, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import config from '../../../config';
import { ListingCard } from '../../../components';
import Slider from "react-slick";
import { util as sdkUtil } from '../../../util/sdkLoader';
import './SectionCarousel.css';
import css from './SectionFilteredSearches.module.css';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.


const sharetribeSdk = require('sharetribe-flex-sdk');


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });



// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionFilteredSearches = props => {
  const { rootClassName, className, currentListingId, publicData } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [mostWantedListings, setMostWantedListings] = useState([]);

  useEffect(() => {
    
    if(typeof window === "undefined"){
     console.log("ceva")
    }else{
     require("slick-carousel/slick/slick-theme.css");
     require("slick-carousel/slick/slick.css");
    }
  
}, []);

  const isServer = typeof window === 'undefined';
  useEffect(() => {
    
    // if(typeof window === "undefined"){
    //  console.log("ceva")
    // }else{
    //  require('@brainhubeu/react-carousel/lib/style.css');
    // }

    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;

    sdk.listings.query({ 
    pub_category: 'has_any:' + (publicData?.category ? publicData?.category[0] : ''),
    pub_designer: 'has_any:' + (publicData?.designer ? publicData?.designer[0] : ''),
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.portrait-crop', 'variants.portrait-crop2x'],
    'imageVariant.portrait-crop': sdkUtil.objectQueryString({
       w: 400,
       h: 600,
       fit: 'crop',
       }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
       w: 800,
       h: 1200,
       fit: 'crop',
     }),    
    'limit.images': 1
  }).then(res => {

      const listings = res.data.data.filter(l => { return l.id.uuid !== currentListingId });
      const images = res.data.included.filter(i => {
        return i.type === 'image'
      });
      const users = res.data.included.filter(i => {
        return i.type === 'user'
      });
      listings.forEach(l => {
          const imageId = l.relationships.images.data[0].id.uuid;
          const authorId = l.relationships.author.data.id.uuid;

          const luckyImage = images.find(i => {
            return i.id.uuid === imageId
          })

          const author = users.find(u => {
            return u.id.uuid === authorId
          })
          l.author = author;
          l.images = [luckyImage];
      })
      setMostWantedListings(listings)


    }).catch(e => console.log(e))
  
}, []);


const cardRenderSizes = () => {
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
};


  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")

  const slidesToShow = typeof window !== 'undefined' ? window.innerWidth < 700 ? 3 : 5 : 5;

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  console.log(mostWantedListings)
  return (
    <div className={classes}>




      <div className={css.title}>
        <FormattedMessage id="SectionFilteredSearches.title" />           
      </div>
      <div className={css.filteredSearches}>
        {
          mostWantedListings?.length < 1 ?
          <p><FormattedMessage id="SectionFilteredSearches.noResults" />  </p>
          :
          <Slider {...settings}>
          {mostWantedListings.map(l => {
              return(
              <div className={css.listingWrapper}>
                    <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes()}
                    setActiveListing={() => console.log('')}
                    thinner={true}
                  />
              </div>
              )
            })}
          </Slider>
        }

      </div>
    </div>
  );
};

SectionFilteredSearches.defaultProps = { rootClassName: null, className: null };

SectionFilteredSearches.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFilteredSearches;
